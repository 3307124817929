import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import PageIllustration from './PageIllustration';
import { Users } from 'lucide-react';

function GenerateArticlePage() {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/articles/${id}`);
        setArticle(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching article:', error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  const handleGenerateArticle = async () => {
    setGenerating(true);
    try {
      const response = await axios.post(`/api/generate-article/${id}`);
      console.log(response);
      navigate(`/generated-article/${id}`);
    } catch (error) {
      console.error('Error generating article:', error);
      alert('記事の生成に失敗しました');
    } finally {
      setGenerating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <p className="text-gray-600">記事が見つかりませんでした。</p>
      </div>
    );
  }

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-4xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="overflow-hidden rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm md:p-8">
            <h1 className="mb-6 text-3xl font-bold tracking-[-0.037em] text-gray-800">
              記事を生成
            </h1>

            <h2 className="mb-4 text-2xl font-bold text-gray-800">
              {article.h1Title}
            </h2>

            <div className="mb-8 text-lg leading-relaxed text-gray-600">
              {article.intro}
            </div>

            {article.sections.map((section, index) => (
              <div key={index} className="mb-8">
                <h3 className="mb-4 text-xl font-bold text-gray-800">
                  {section.h2Title}
                </h3>
                {section.h3Sections.map((h3Section, h3Index) => (
                  <div key={h3Index} className="mb-6 ml-4">
                    <h4 className="mb-2 text-lg font-semibold text-gray-800">
                      {h3Section.h3Title}
                    </h4>
                    <div className="text-gray-600">
                      {h3Section.notes}
                    </div>
                  </div>
                ))}
              </div>
            ))}

            <div className="mb-8">
              <h3 className="mb-4 text-xl font-bold text-gray-800">
                よくある質問
              </h3>
              <div className="space-y-6">
                {article.faq.map((faq, index) => (
                  <div key={index} className="rounded-xl bg-gray-50 p-6">
                    <h4 className="mb-2 text-lg font-semibold text-gray-800">
                      Q: {faq.question}
                    </h4>
                    <div className="text-gray-600">
                      A: {faq.answer}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-4 sm:flex-row sm:justify-between">
              <Link
                to={`/target-audience/${id}`}
                className="inline-flex items-center justify-center rounded-xl bg-white px-6 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50"
              >
                <Users className="mr-2 h-4 w-4" />
                ターゲット設定を確認
              </Link>

              <button
                onClick={handleGenerateArticle}
                disabled={generating}
                className="inline-flex items-center justify-center rounded-xl bg-gradient-to-t from-gray-800 to-gray-700 px-6 py-3 text-sm font-medium text-white shadow-sm hover:to-gray-600 disabled:opacity-50"
              >
                {generating ? (
                  <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                ) : (
                  '記事を生成'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GenerateArticlePage;