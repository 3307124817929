import axios from 'axios';
import { config } from '../config/environment';

const api = axios.create({
  baseURL: `${config.apiBaseUrl}/api`,
});

// Existing API methods
export const getArticle = async (id) => {
  try {
    const response = await api.get(`/articles/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching article:', error);
    throw error;
  }
};

export const createTargetAudience = async (articleId, data) => {
  try {
    const response = await api.post('/create-target-audience', {
      articleId,
      ...data
    });
    return response.data;
  } catch (error) {
    console.error('Error creating target audience:', error);
    throw error;
  }
};

export const generateArticle = async (id) => {
  try {
    const response = await api.post(`/generate-article/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error generating article:', error);
    throw error;
  }
};

export const createQuickArticle = async (keyword, language = '日本語') => {
  try {
    const response = await api.post('/quick-articles', { keyword, language });
    return response.data;
  } catch (error) {
    console.error('Error creating quick article:', error);
    throw error;
  }
};

export const getQuickArticles = async () => {
  try {
    const response = await api.get('/quick-articles');
    return response.data;
  } catch (error) {
    console.error('Error fetching quick articles:', error);
    throw error;
  }
};