import React, { useState } from 'react';
import PageIllustration from './PageIllustration';

const PricingPage = () => {
  const [isYearly, setIsYearly] = useState(false);

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 pt-32 md:pt-40">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 
              className="text-3xl font-bold tracking-[-0.037em] text-gray-800 sm:text-4xl lg:text-5xl animate-fade-in-up"
              style={{
                textShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                animation: 'fade-in-up 0.5s ease-out'
              }}
            >
              シンプルな料金プラン
            </h2>
            <p 
              className="max-w-md mx-auto mt-4 text-lg text-gray-600 animate-fade-in"
              style={{
                animation: 'fade-in 0.5s ease-out 0.1s backwards'
              }}
            >
              必要な機能を、必要な分だけ。
              スケーラブルな料金体系で、ビジネスの成長をサポートします。
            </p>
          </div>

          <div className="mt-10 animate-fade-in" style={{ animation: 'fade-in 0.5s ease-out 0.2s backwards' }}>
            <div className="flex items-center justify-center space-x-2.5">
              <span className="text-base font-medium text-gray-900">月額</span>
              <button
                onClick={() => setIsYearly(!isYearly)}
                type="button"
                className={`relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                  isYearly ? 'bg-gray-800' : 'bg-gray-200'
                }`}
                role="switch"
                aria-checked={isYearly}
              >
                <span
                  className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                    isYearly ? 'translate-x-6' : 'translate-x-0'
                  }`}
                />
              </button>
              <span className="text-base font-medium text-gray-900">年額</span>
            </div>
          </div>

          <div className="grid max-w-3xl grid-cols-1 gap-6 mx-auto mt-8 sm:mt-14 sm:grid-cols-2 md:gap-9 animate-fade-in" 
            style={{ animation: 'fade-in 0.5s ease-out 0.3s backwards' }}
          >
            {/* Basic Plan */}
            <div className="overflow-hidden bg-white/90 backdrop-blur-sm rounded-2xl border-2 border-gray-200 hover:scale-102 transition-all duration-200">
              <div className="p-6 md:py-8 md:px-9">
                <h3 className="text-xl font-semibold text-gray-800">スタンダード</h3>
                <p className="mt-2.5 text-sm text-gray-600">
                  個人・フリーランス向けの基本プラン
                </p>

                <div className="flex items-end mt-5">
                  <div className="flex items-start">
                    <span className="text-xl font-medium text-gray-800">¥</span>
                    <p className="text-6xl font-medium tracking-tight text-gray-800">
                      {isYearly ? '3,900' : '4,900'}
                    </p>
                  </div>
                  <span className="ml-0.5 text-lg text-gray-600">
                    / {isYearly ? '月' : '月'}
                  </span>
                </div>

                <button className="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-gray-900 transition-all duration-200 bg-transparent border-2 rounded-xl border-gray-800 hover:bg-gray-800 hover:text-white">
                  1記事は無料
                </button>

                <ul className="flex flex-col mt-8 space-y-4">
                  <li className="inline-flex items-center space-x-2">
                    <svg className="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="text-base font-medium text-gray-900">月間10記事まで生成可能</span>
                  </li>
                  <li className="inline-flex items-center space-x-2">
                    <svg className="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="text-base font-medium text-gray-900">基本的なSEO最適化</span>
                  </li>
                  <li className="inline-flex items-center space-x-2">
                    <svg className="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="text-base font-medium text-gray-900">画像生成5枚/記事</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Pro Plan */}
            <div className="overflow-hidden bg-white/90 backdrop-blur-sm rounded-2xl border-2 border-gray-800 hover:scale-102 transition-all duration-200">
              <div className="p-6 md:py-8 md:px-9">
                <h3 className="text-xl font-semibold text-gray-800">プロフェッショナル</h3>
                <p className="mt-2.5 text-sm text-gray-600">
                  企業・プロフェッショナル向けの高機能プラン
                </p>

                <div className="flex items-end mt-5">
                  <div className="flex items-start">
                    <span className="text-xl font-medium text-gray-800">¥</span>
                    <p className="text-6xl font-medium tracking-tight text-gray-800">
                      {isYearly ? '9,900' : '12,900'}
                    </p>
                  </div>
                  <span className="ml-0.5 text-lg text-gray-600">
                    / {isYearly ? '月' : '月'}
                  </span>
                </div>

                <button className="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-white transition-all duration-200 border-2 border-transparent rounded-xl bg-gray-800 hover:opacity-80">
                  1記事は無料
                </button>

                <ul className="flex flex-col mt-8 space-y-4">
                  <li className="inline-flex items-center space-x-2">
                    <svg className="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="text-base font-medium text-gray-900">無制限の記事生成</span>
                  </li>
                  <li className="inline-flex items-center space-x-2">
                    <svg className="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="text-base font-medium text-gray-900">高度なSEO最適化</span>
                  </li>
                  <li className="inline-flex items-center space-x-2">
                    <svg className="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="text-base font-medium text-gray-900">画像生成無制限</span>
                  </li>
                  <li className="inline-flex items-center space-x-2">
                    <svg className="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="text-base font-medium text-gray-900">優先サポート</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>{`
        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(8px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(12px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in {
          animation: fade-in 0.5s ease-out;
        }

        .animate-fade-in-up {
          animation: fade-in-up 0.5s ease-out;
        }

        /* Faster hover animations */
        .hover\\:scale-102:hover {
          transform: scale(1.02);
        }
      `}</style>
    </section>
  );
};

export default PricingPage;