import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button, Paper, Box, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function TargetAudiencePage() {
  const [targetAudience, setTargetAudience] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTargetAudience = async () => {
      try {
        const response = await axios.get(`/api/target-audience/${id}`);
        setTargetAudience(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching target audience:', error);
        setLoading(false);
      }
    };

    fetchTargetAudience();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this target audience?')) {
      setDeleting(true);
      try {
        await axios.delete(`/api/target-audience/${id}`);
        setTargetAudience(null);
      } catch (error) {
        console.error('Error deleting target audience:', error);
        alert('Failed to delete target audience. Please try again.');
      } finally {
        setDeleting(false);
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4, position: 'relative' }}>
        <Typography variant="h4" component="h1" gutterBottom color="primary">
          Target Audience
        </Typography>
        {targetAudience ? (
          <>
            <IconButton
              onClick={handleDelete}
              disabled={deleting}
              sx={{ position: 'absolute', top: 16, right: 16 }}
            >
              <DeleteIcon />
            </IconButton>
            <Typography variant="body1" component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
              {targetAudience.analysis}
            </Typography>
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/create-target-audience/${id}`)}
            >
              Create Target Audience
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
}

export default TargetAudiencePage;