import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PageIllustration from './PageIllustration';
import { Pencil, Trash2, Users, FileText, Link, ChevronLeft, ChevronRight } from 'lucide-react';

function ArticleList() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 10;

  useEffect(() => {
    fetchArticles();
  }, [currentPage]);

  const fetchArticles = async () => {
    try {
      const response = await axios.get('/api/articles');
      const articlesWithWordPressLinks = await Promise.all(
        response.data.map(async (article) => {
          try {
            const wpResponse = await axios.get(`/api/articles/${article.id}/wordpress-link`);
            return { ...article, wordpressLink: wpResponse.data.wordpress_link };
          } catch (error) {
            console.error(`Error fetching WordPress link for article ${article.id}:`, error);
            return { ...article, wordpressLink: null };
          }
        })
      );
      setArticles(articlesWithWordPressLinks);
      setTotalPages(Math.ceil(articlesWithWordPressLinks.length / itemsPerPage));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('この記事を削除してもよろしいですか？')) {
      try {
        await axios.delete(`/api/articles/${id}`);
        setArticles(articles.filter(article => article.id !== id));
        // Update total pages after deletion
        const remainingArticles = articles.filter(article => article.id !== id);
        setTotalPages(Math.ceil(remainingArticles.length / itemsPerPage));
        // If current page is now empty, go to previous page
        if (currentPage > Math.ceil(remainingArticles.length / itemsPerPage)) {
          setCurrentPage(prev => Math.max(prev - 1, 1));
        }
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };

  const getPaginatedArticles = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return articles.slice(startIndex, endIndex);
  };

  const goToPage = (page) => {
    setCurrentPage(Math.min(Math.max(1, page), totalPages));
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="mb-8 text-center">
            <h1 className="mb-4 text-3xl font-bold tracking-[-0.037em] text-gray-800 md:text-4xl lg:text-5xl">
              記事一覧
            </h1>
          </div>

          <div className="space-y-4">
            {getPaginatedArticles().map((article) => (
              <div
                key={article.id}
                className="overflow-hidden rounded-2xl bg-white/90 shadow-lg backdrop-blur-sm transition-all hover:shadow-xl"
              >
                <div className="flex flex-col gap-4 p-6 sm:flex-row sm:items-center sm:justify-between">
                  <div className="flex-1 cursor-pointer" onClick={() => navigate(`/articles/${article.id}`)}>
                    <h2 className="text-xl font-semibold text-gray-800 hover:text-gray-600">
                      {article.h1Title}
                    </h2>
                  </div>
                  
                  <div className="flex flex-wrap items-center gap-2">
                    {/* WordPress Link */}
                    <button
                      onClick={() => article.wordpressLink && window.open(article.wordpressLink, '_blank')}
                      disabled={!article.wordpressLink}
                      className={`rounded-xl p-2 transition-colors ${
                        article.wordpressLink
                          ? 'text-blue-600 hover:bg-blue-50'
                          : 'text-gray-400'
                      }`}
                      title={article.wordpressLink ? "WordPressで開く" : "WordPressの記事はまだ生成されていません"}
                    >
                      <Link size={20} />
                    </button>

                    <button
                      onClick={() => navigate(`/generated-article/${article.id}`)}
                      className="rounded-xl p-2 text-gray-600 transition-colors hover:bg-gray-100"
                      title="生成された記事を表示"
                    >
                      <FileText size={20} />
                    </button>

                    <button
                      onClick={() => navigate(`/target-audience/${article.id}`)}
                      className="rounded-xl p-2 text-gray-600 transition-colors hover:bg-gray-100"
                      title="ターゲット設定"
                    >
                      <Users size={20} />
                    </button>

                    <button
                      onClick={() => navigate(`/edit/${article.id}`)}
                      className="rounded-xl p-2 text-gray-600 transition-colors hover:bg-gray-100"
                      title="編集"
                    >
                      <Pencil size={20} />
                    </button>

                    <button
                      onClick={() => handleDelete(article.id)}
                      className="rounded-xl p-2 text-red-500 transition-colors hover:bg-red-50"
                      title="削除"
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {articles.length === 0 && (
              <div className="rounded-2xl bg-white/90 p-8 text-center shadow-lg backdrop-blur-sm">
                <p className="text-gray-600">記事がありません</p>
              </div>
            )}

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex items-center justify-center gap-2 pt-6">
                <button
                  onClick={() => goToPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="inline-flex items-center rounded-xl bg-white px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50"
                >
                  <ChevronLeft size={16} className="mr-1" />
                  前へ
                </button>

                {/* Page numbers */}
                <div className="flex items-center gap-1">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <button
                      key={page}
                      onClick={() => goToPage(page)}
                      className={`inline-flex h-8 w-8 items-center justify-center rounded-xl text-sm font-medium ${
                        currentPage === page
                          ? 'bg-gray-800 text-white'
                          : 'bg-white text-gray-800 hover:bg-gray-50'
                      }`}
                    >
                      {page}
                    </button>
                  ))}
                </div>

                <button
                  onClick={() => goToPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="inline-flex items-center rounded-xl bg-white px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50"
                >
                  次へ
                  <ChevronRight size={16} className="ml-1" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArticleList;