import React from 'react';
import PageIllustration from './PageIllustration';

function Guide() {
  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
        <div className="flex min-h-[calc(100vh-8rem)] items-center justify-center pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="text-center">
            <h1 
              className="mb-4 animate-fade-in text-4xl font-bold tracking-[-0.037em] text-gray-800 md:text-6xl lg:text-7xl"
              style={{
                textShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                animation: 'fade-in 1s ease-out'
              }}
            >
              Coming Soon
            </h1>
            <p className="text-lg text-gray-600 md:text-xl">
              ガイドページは現在準備中です
            </p>
          </div>
        </div>
      </div>

      <style>{`
        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </section>
  );
}

export default Guide;