import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, TextField, Button, Box, Paper, IconButton, CircularProgress, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function CreateTargetAudience() {
  const [article, setArticle] = useState(null);
  const [conditions, setConditions] = useState(['']);
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/articles/${id}`);
        setArticle(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching article:', error);
        setError('Failed to fetch article. Please try again.');
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  const handleConditionChange = (index, value) => {
    const newConditions = [...conditions];
    newConditions[index] = value;
    setConditions(newConditions);
  };

  const addCondition = () => {
    setConditions([...conditions, '']);
  };

  const removeCondition = (index) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);
    setError(null);
    try {
      const response = await axios.post('/api/create-target-audience', {
        articleId: id,
        conditions: conditions.filter(condition => condition.trim() !== '').join('\n')
      });
      console.log(response)
      navigate(`/target-audience/${id}`);
    } catch (error) {
      console.error('Error creating target audience:', error);
      if (error.response && error.response.data) {
        setError(`Failed to create target audience: ${error.response.data.error || 'Unknown error'}`);
        if (error.response.data.traceback) {
          console.error('Error traceback:', error.response.data.traceback);
        }
      } else {
        setError('Failed to create target audience. Please try again.');
      }
    } finally {
      setCreating(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!article) return <Typography>Article not found.</Typography>;

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom color="primary">
          Create Target Audience
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Article: {article.h1Title}
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          {conditions.map((condition, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                fullWidth
                label={`Condition ${index + 1}`}
                value={condition}
                onChange={(e) => handleConditionChange(index, e.target.value)}
                margin="normal"
                variant="outlined"
              />
              <IconButton onClick={() => removeCondition(index)} color="error">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button startIcon={<AddIcon />} onClick={addCondition} variant="outlined" sx={{ mt: 2, mb: 4 }}>
            Add New Condition
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              size="large"
              disabled={creating}
            >
              {creating ? <CircularProgress size={24} /> : 'Create Target Audience'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateTargetAudience;