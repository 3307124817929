import React from 'react';
import { useUser, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import PageIllustration from './PageIllustration';

function UserProfile() {
  const { isLoaded, user } = useUser();

  if (!isLoaded) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const renderContent = () => {
    return (
      <div className="space-y-8" data-aos="zoom-y-out" data-aos-delay="300">
        {/* Profile Card */}
        <div className="max-w-3xl mx-auto rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm">
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-6">
            <div className="shrink-0">
              <img
                src={user.imageUrl}
                alt={user.firstName}
                className="h-24 w-24 rounded-full object-cover shadow-lg"
              />
            </div>
            <div className="flex-1 text-center sm:text-left">
              <h2 className="text-2xl font-bold tracking-[-0.037em] text-gray-800 mb-2">
                {user.firstName} {user.lastName}
              </h2>
              <p className="text-gray-600 mb-2">{user.primaryEmailAddress?.emailAddress}</p>
              <div className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1">
                <span className="h-2 w-2 rounded-full bg-green-500"></span>
                <span className="ml-2 text-sm font-medium text-gray-600">
                  {user.publicMetadata?.isApproved ? 'Approved Account' : 'Pending Approval'}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Menu Items */}
        {user?.publicMetadata?.isApproved && (
          <div className="max-w-md mx-auto space-y-4">
            <Link
              to="/articles"
              className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
            >
              <div>
                <h3 className="text-xl font-bold text-gray-800 mb-1">記事一覧</h3>
                <p className="text-sm text-gray-600">これまでに生成した記事を確認・編集</p>
              </div>
              <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
                →
              </span>
            </Link>

            <Link
              to="/create"
              className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
            >
              <div>
                <h3 className="text-xl font-bold text-gray-800 mb-1">新規作成</h3>
                <p className="text-sm text-gray-600">詳細設定で記事を生成</p>
              </div>
              <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
                →
              </span>
            </Link>

            <Link
              to="/quick-generate"
              className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
            >
              <div>
                <h3 className="text-xl font-bold text-gray-800 mb-1">クイック生成</h3>
                <p className="text-sm text-gray-600">キーワードだけで簡単に記事を生成</p>
              </div>
              <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
                →
              </span>
            </Link>
          </div>
        )}
      </div>
    );
  };

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <SignedIn>
        <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
          <div className="pb-12 pt-32 md:pb-20 md:pt-40">
            {renderContent()}
          </div>
        </div>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </section>
  );
}

export default UserProfile;