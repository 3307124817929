import React from 'react';
import { useUser } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import PageIllustration from './PageIllustration';

function Menu() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const renderContent = () => {
    if (!isSignedIn) {
      return (
        <div className="text-center">
          <h2 className="mb-6 text-3xl font-bold tracking-[-0.037em] text-gray-800">
            ログインしてください
          </h2>
          <Link
            to="/sign-in"
            className="btn group relative inline-flex items-center bg-gradient-to-t from-gray-800 to-gray-700 px-8 py-3 text-white shadow-sm hover:to-gray-600 transition-all rounded-xl"
          >
            ログイン
            <span className="ml-2 tracking-normal text-gray-300 transition-transform duration-150 ease-in-out group-hover:translate-x-0.5">
              →
            </span>
          </Link>
        </div>
      );
    }

    if (!user?.publicMetadata?.isApproved) {
      return (
        <div className="max-w-2xl mx-auto p-6 rounded-2xl bg-white/90 shadow-xl backdrop-blur-sm">
          <h2 className="text-2xl font-bold tracking-[-0.037em] text-gray-800 mb-4">
            アカウント承認待ち
          </h2>
          <p className="text-gray-600">
            アカウントの承認をお待ちください。承認されましたら、記事生成機能をご利用いただけます。
          </p>
        </div>
      );
    }

    return (
      <div className="max-w-md mx-auto space-y-4" data-aos="zoom-y-out" data-aos-delay="300">
        <Link
          to="/articles"
          className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
        >
          <div>
            <h3 className="text-xl font-bold text-gray-800 mb-1">記事一覧</h3>
            <p className="text-sm text-gray-600">これまでに生成した記事を確認・編集</p>
          </div>
          <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
            →
          </span>
        </Link>

        <Link
          to="/create"
          className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
        >
          <div>
            <h3 className="text-xl font-bold text-gray-800 mb-1">新規作成</h3>
            <p className="text-sm text-gray-600">詳細設定で記事を生成</p>
          </div>
          <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
            →
          </span>
        </Link>

        <Link
          to="/quick-generate"
          className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
        >
          <div>
            <h3 className="text-xl font-bold text-gray-800 mb-1">クイック生成</h3>
            <p className="text-sm text-gray-600">キーワードだけで簡単に記事を生成</p>
          </div>
          <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
            →
          </span>
        </Link>
      </div>
    );
  };

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="pb-12 text-center md:pb-16">
            <h1
              className="mb-4 text-3xl font-bold tracking-[-0.037em] md:text-4xl lg:text-5xl"
              data-aos="zoom-y-out"
            >
              Sense AISEOへようこそ
            </h1>
            <p 
              className="text-lg text-gray-600 mb-8"
              data-aos="zoom-y-out" 
              data-aos-delay="150"
            >
              <strong>記事生成モードを選択してください</strong>
            </p>
            {renderContent()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Menu;