const getApiBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    // In development, return empty string to use the proxy
    return '';
  }
  // In production, use the window location
  return window.location.origin;
};

export const config = {
  apiBaseUrl: getApiBaseUrl(),
  // Add other environment-specific configurations as needed
};