import React, { useState } from 'react';
import axios from 'axios';
import { Plus, Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import PageIllustration from './PageIllustration';

function CreateOutline() {
  const [article, setArticle] = useState({
    h1Title: '',
    intro: '',
    sections: [{ h2Title: '', h3Sections: [{ h3Title: '', notes: '' }] }],
    faq: [{ question: '', answer: '' }]
  });

  const [expandedSections, setExpandedSections] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await axios.post('/api/articles', article);
      alert('記事が作成されました');
      // Reset form or redirect
    } catch (error) {
      console.error('Error creating article:', error);
      setError('記事の作成に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  const handleH1Change = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 60) {
      setArticle({ ...article, h1Title: newValue });
    }
  };

  const handleIntroChange = (e) => {
    setArticle({ ...article, intro: e.target.value });
  };

  const handleH2Change = (index, value) => {
    const newSections = [...article.sections];
    newSections[index].h2Title = value;
    setArticle({ ...article, sections: newSections });
  };

  const handleH3Change = (sectionIndex, h3Index, field, value) => {
    const newSections = [...article.sections];
    newSections[sectionIndex].h3Sections[h3Index][field] = value;
    setArticle({ ...article, sections: newSections });
  };

  const handleFAQChange = (index, field, value) => {
    const newFAQ = [...article.faq];
    newFAQ[index][field] = value;
    setArticle({ ...article, faq: newFAQ });
  };

  const addSection = () => {
    setArticle({
      ...article,
      sections: [...article.sections, { h2Title: '', h3Sections: [{ h3Title: '', notes: '' }] }]
    });
    // Auto-expand new section
    setExpandedSections(prev => ({
      ...prev,
      [article.sections.length]: true
    }));
  };

  const addH3Section = (sectionIndex) => {
    const newSections = [...article.sections];
    newSections[sectionIndex].h3Sections.push({ h3Title: '', notes: '' });
    setArticle({ ...article, sections: newSections });
  };

  const addFAQ = () => {
    setArticle({
      ...article,
      faq: [...article.faq, { question: '', answer: '' }]
    });
  };

  const removeSection = (index) => {
    if (article.sections.length > 1) {
      const newSections = article.sections.filter((_, i) => i !== index);
      setArticle({ ...article, sections: newSections });
      // Clean up expanded sections state
      const newExpandedSections = { ...expandedSections };
      delete newExpandedSections[index];
      setExpandedSections(newExpandedSections);
    }
  };

  const removeH3Section = (sectionIndex, h3Index) => {
    if (article.sections[sectionIndex].h3Sections.length > 1) {
      const newSections = [...article.sections];
      newSections[sectionIndex].h3Sections = newSections[sectionIndex].h3Sections.filter((_, i) => i !== h3Index);
      setArticle({ ...article, sections: newSections });
    }
  };

  const removeFAQ = (index) => {
    if (article.faq.length > 1) {
      const newFAQ = article.faq.filter((_, i) => i !== index);
      setArticle({ ...article, faq: newFAQ });
    }
  };

  const toggleSection = (index) => {
    setExpandedSections(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const renderSection = (section, sectionIndex) => {
    const isExpanded = expandedSections[sectionIndex] !== false;

    return (
      <div key={sectionIndex} className="mb-4 overflow-hidden rounded-2xl bg-white/90 shadow-lg backdrop-blur-sm">
        <div className="border-b border-gray-200 p-4">
          <div className="flex items-center justify-between">
            <div className="flex-1 pr-4">
              <input
                type="text"
                placeholder={`セクション ${sectionIndex + 1} のタイトル`}
                value={section.h2Title}
                onChange={(e) => handleH2Change(sectionIndex, e.target.value)}
                className="w-full rounded-xl bg-transparent px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
              />
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => toggleSection(sectionIndex)}
                className="rounded-xl p-2 text-gray-500 hover:bg-gray-100"
              >
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </button>
              <button
                type="button"
                onClick={() => removeSection(sectionIndex)}
                className="rounded-xl p-2 text-red-500 hover:bg-red-50 disabled:opacity-50"
                disabled={article.sections.length <= 1}
              >
                <Trash2 size={20} />
              </button>
            </div>
          </div>
        </div>

        {isExpanded && (
          <div className="p-4">
            {section.h3Sections.map((h3Section, h3Index) => (
              <div key={h3Index} className="mb-4 rounded-xl bg-gray-50 p-4">
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder={`小見出し ${h3Index + 1}`}
                    value={h3Section.h3Title}
                    onChange={(e) => handleH3Change(sectionIndex, h3Index, 'h3Title', e.target.value)}
                    className="w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div className="mb-2">
                  <textarea
                    placeholder="メモ"
                    value={h3Section.notes}
                    onChange={(e) => handleH3Change(sectionIndex, h3Index, 'notes', e.target.value)}
                    rows="4"
                    className="w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => removeH3Section(sectionIndex, h3Index)}
                    className="rounded-xl p-2 text-red-500 hover:bg-red-50 disabled:opacity-50"
                    disabled={section.h3Sections.length <= 1}
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addH3Section(sectionIndex)}
              className="inline-flex items-center rounded-xl bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
            >
              <Plus size={16} className="mr-2" />
              小見出しを追加
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-5xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          {/* Title */}
          <div className="mb-8 text-center">
            <h1 className="mb-4 text-3xl font-bold tracking-[-0.037em] text-gray-800 md:text-4xl lg:text-5xl">
              記事アウトラインの作成
            </h1>
            <p className="text-lg text-gray-600">
              記事の構成を詳細に設定できます
            </p>
          </div>

          {/* Main Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* H1 Title */}
            <div className="relative rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <label className="mb-2 block text-sm font-medium text-gray-700">メインタイトル</label>
              <input
                type="text"
                value={article.h1Title}
                onChange={handleH1Change}
                maxLength={60}
                className="w-full rounded-xl bg-transparent px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                placeholder="メインタイトルを入力（60文字以内）"
              />
              <div className="mt-1 text-right text-sm text-gray-500">
                {article.h1Title.length}/60文字
              </div>
            </div>

            {/* Intro */}
            <div className="rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <label className="mb-2 block text-sm font-medium text-gray-700">導入文</label>
              <textarea
                value={article.intro}
                onChange={handleIntroChange}
                rows="4"
                className="w-full rounded-xl bg-transparent px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                placeholder="記事の導入文を入力"
              />
            </div>

            {/* Sections */}
            <div className="space-y-4">
              {article.sections.map((section, index) => renderSection(section, index))}
              <button
                type="button"
                onClick={addSection}
                className="inline-flex w-full items-center justify-center rounded-xl bg-gray-800 px-6 py-3 text-sm font-medium text-white hover:bg-gray-700"
              >
                <Plus size={20} className="mr-2" />
                新しいセクションを追加
              </button>
            </div>

            {/* FAQ Section */}
            <div className="rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm">
              <h2 className="mb-4 text-xl font-bold text-gray-800">よくある質問</h2>
              <div className="space-y-4">
                {article.faq.map((faq, index) => (
                  <div key={index} className="rounded-xl bg-gray-50 p-4">
                    <input
                      type="text"
                      placeholder={`質問 ${index + 1}`}
                      value={faq.question}
                      onChange={(e) => handleFAQChange(index, 'question', e.target.value)}
                      className="mb-2 w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                    />
                    <textarea
                      placeholder="回答"
                      value={faq.answer}
                      onChange={(e) => handleFAQChange(index, 'answer', e.target.value)}
                      rows="4"
                      className="mb-2 w-full rounded-xl bg-white px-4 py-2 text-gray-800 outline-none ring-1 ring-gray-200 focus:ring-2 focus:ring-gray-400"
                    />
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => removeFAQ(index)}
                        className="rounded-xl p-2 text-red-500 hover:bg-red-50 disabled:opacity-50"
                        disabled={article.faq.length <= 1}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addFAQ}
                  className="inline-flex items-center rounded-xl bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                >
                  <Plus size={16} className="mr-2" />
                  FAQを追加
                </button>
              </div>
            </div>

            {/* Error Message */}
            {error && (
              <div className="rounded-xl bg-red-50 p-4 text-center text-red-600">
                {error}
              </div>
            )}

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={loading}
                className="inline-flex items-center rounded-xl bg-gradient-to-t from-gray-800 to-gray-700 px-8 py-3 text-white shadow-sm hover:to-gray-600 disabled:opacity-50"
              >
                {loading ? (
                  <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                ) : (
                  'アウトラインを作成'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default CreateOutline;