import React from 'react';
import stripes from '../images/stripes.svg';

const PageIllustration = () => {
  return (
    <div className="fixed inset-0 w-full min-h-screen pointer-events-none">
      {/* Stripes background */}
      <div className="absolute inset-0 w-full h-full" aria-hidden="true">
        <img 
          src={stripes} 
          alt="" 
          className="w-full h-full object-cover opacity-25"
        />
      </div>

      {/* Gradient circles */}
      <div
        className="pointer-events-none absolute -top-32 left-1/2 ml-0 md:ml-[580px] -translate-x-1/2"
        aria-hidden="true"
      >
        <div className="h-80 w-80 rounded-full bg-gradient-to-tr from-blue-500 opacity-50 blur-[160px]" />
      </div>
      <div
        className="pointer-events-none absolute left-1/2 top-[420px] ml-0 md:ml-[380px] -translate-x-1/2"
        aria-hidden="true"
      >
        <div className="h-80 w-80 rounded-full bg-gradient-to-tr from-blue-500 to-gray-900 opacity-50 blur-[160px]" />
      </div>
      <div
        className="pointer-events-none absolute left-1/2 top-[640px] -ml-0 md:-ml-[300px] -translate-x-1/2"
        aria-hidden="true"
      >
        <div className="h-80 w-80 rounded-full bg-gradient-to-tr from-blue-500 to-gray-900 opacity-50 blur-[160px]" />
      </div>
    </div>
  );
};

export default PageIllustration;