import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView, AnimatePresence } from 'framer-motion';
import { 
  Sparkles, ArrowRight, Moon, Sun, Loader2, Type, 
  LayoutIcon, ImageIcon, ChevronDown, Star
} from 'lucide-react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useTheme } from 'next-themes';


const AnimatedBackground = () => {
  return (
    <div className="fixed inset-0 z-0 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-purple-900 to-indigo-900" />
      <div className="absolute inset-0">
        {[...Array(80)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute rounded-full mix-blend-screen filter blur-xl"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 100 + 20}px`,
              height: `${Math.random() * 100 + 20}px`,
              background: `radial-gradient(circle, rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.3) 0%, rgba(255,255,255,0) 70%)`,
            }}
            animate={{
              x: [0, Math.random() * 400 - 200],
              y: [0, Math.random() * 400 - 200],
              scale: [1, 1.2, 1],
              rotate: [0, 360],
            }}
            transition={{
              duration: Math.random() * 20 + 10,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: "easeInOut"
            }}
          />
        ))}
      </div>
    </div>
  );
};

const AnimatedFeature = ({ icon: Icon, title, description }) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
      }}
      whileHover={{ scale: 1.05, boxShadow: '0 10px 30px rgba(0,0,0,0.1)' }}
      className="bg-white/10 backdrop-blur-md rounded-xl p-6 shadow-lg border border-purple-500/30 transition-all duration-300 hover:border-purple-400 group"
    >
      <div className="flex items-center mb-4">
        <Icon className="w-8 h-8 text-purple-400 mr-3 group-hover:text-purple-300 transition-colors duration-300" />
        <h3 className="text-xl font-semibold text-white group-hover:text-purple-300 transition-colors duration-300">{title}</h3>
      </div>
      <p className="text-gray-300 group-hover:text-gray-200 transition-colors duration-300 leading-relaxed">{description}</p>
    </motion.div>
  );
};

const PricingCard = ({ title, price, features, isPopular }) => (
  <motion.div
    whileHover={{ scale: 1.05, boxShadow: '0 10px 30px rgba(0,0,0,0.2)' }}
    className={`bg-white/10 backdrop-blur-md rounded-xl p-6 shadow-lg border ${
      isPopular ? 'border-purple-400' : 'border-purple-500/30'
    } transition-all duration-300 relative overflow-hidden`}
  >
    {isPopular && (
      <div className="absolute top-0 right-0">
        <div className="bg-purple-500 text-white text-sm font-semibold py-1 px-4 rounded-bl-lg shadow-md">
          人気プラン
        </div>
      </div>
    )}
    <h3 className="text-2xl font-bold text-white mb-2">{title}</h3>
    <p className="text-3xl font-bold text-purple-400 mb-4">{price}</p>
    <ul className="space-y-2 mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-gray-300">
          <Star className="w-5 h-5 text-purple-400 mr-2 flex-shrink-0" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <Button className="w-full bg-gradient-to-r from-purple-400 to-pink-400 hover:from-purple-500 hover:to-pink-500 text-white font-semibold py-3 rounded-lg transition-colors duration-300 transform hover:scale-105">
      選択する
    </Button>
  </motion.div>
);

const FloatingElement = ({ children, delay = 0 }) => (
  <motion.div
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20,
      delay: delay
    }}
  >
    {children}
  </motion.div>
);

const ShimmeringText = ({ children }) => (
  <span className="relative">
    <span className="relative z-10">{children}</span>
    <motion.span
      className="absolute inset-0 bg-gradient-to-r from-transparent via-purple-400 to-transparent opacity-30"
      animate={{
        x: ['-100%', '100%'],
      }}
      transition={{
        repeat: Infinity,
        duration: 2,
        ease: 'linear',
      }}
    />
  </span>
);

function LandingPage() {
  const [keyword, setKeyword] = useState('');
  const { theme, setTheme } = useTheme();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [generatedArticle, setGeneratedArticle] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsGenerating(true);
    setProgress(0);
    setGeneratedArticle('');

    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setIsGenerating(false);
          setGeneratedArticle(`これは「${keyword}」に関する生成された記事のサンプルです。実際のAIによる生成コンテンツはこの部分に表示されます。ここでは、キーワードに基づいた最適化されたSEO記事が表示されます。`);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 50);

    console.log('Submitted keyword:', keyword);
  };

  return (
    <div className="relative min-h-screen overflow-hidden text-white">
      <AnimatedBackground />

      <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-gray-900/90 backdrop-blur-md shadow-lg' : ''
      }`}>
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center space-x-2"
          >
            <Sparkles className="w-8 h-8 text-purple-400" />
            <span className="text-2xl font-bold text-white">AIライティング</span>
          </motion.div>
          <nav className="hidden md:flex items-center space-x-6">
            <motion.a
              href="#features"
              className="text-gray-300 hover:text-white transition-colors text-lg font-medium"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              機能
            </motion.a>
            <motion.a
              href="#pricing"
              className="text-gray-300 hover:text-white transition-colors text-lg font-medium"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              料金
            </motion.a>
            <motion.button
              onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
              className="p-2 bg-gray-800 rounded-full shadow-lg hover:bg-gray-700 transition-colors duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              aria-label="Toggle Theme"
            >
              {theme === 'dark' ? <Sun className="w-6 h-6 text-yellow-400" /> : <Moon className="w-6 h-6 text-purple-400" />}
            </motion.button>
          </nav>
        </div>
      </header>

      <main className="relative z-10 pt-32 pb-20 px-4">
        <div className="container mx-auto max-w-4xl">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center space-y-8"
          >
            <FloatingElement delay={0.2}>
              <motion.div 
                className="inline-flex items-center space-x-2 bg-gradient-to-r from-purple-400 to-pink-400 text-white rounded-full px-6 py-3 text-lg font-medium shadow-xl"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Sparkles className="w-6 h-6 animate-pulse" />
                <span>次世代AIコンテンツ最適化</span>
              </motion.div>
            </FloatingElement>
            
            <FloatingElement delay={0.4}>
              <h1 className="text-4xl md:text-6xl font-extrabold leading-tight tracking-tight text-white">
                キーワード入力だけで、プロ品質の
                <ShimmeringText>
                  <span className="block text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
                    SEO記事が完成
                  </span>
                </ShimmeringText>
              </h1>
            </FloatingElement>
            
            <FloatingElement delay={0.6}>
              <p className="text-xl md:text-2xl max-w-2xl mx-auto text-gray-300 font-medium leading-relaxed">
                AIが実現する、新時代のコンテンツ作成
                <br />
                3分で完成｜SEO対策済み｜画像最適化付き
              </p>
            </FloatingElement>

            <FloatingElement delay={0.8}>
              <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                <div className="relative">
                  <Input
                    id="keyword"
                    type="text"
                    placeholder="最適化したいキーワードを入力"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    className="w-full h-14 pl-5 pr-36 text-lg bg-white/10 backdrop-blur-md border-2 border-purple-500/30 focus:border-purple-400 focus:ring-2 focus:ring-purple-400 rounded-full shadow-lg transition-all duration-300 text-white placeholder-gray-400"
                    aria-label="最適化キーワード"
                  />
                  <Button 
                    type="submit"
                    size="lg"
                    className="absolute right-2 top-2 h-10 px-6 bg-gradient-to-r from-purple-400 to-pink-400 hover:from-purple-500 hover:to-pink-500 text-white text-base rounded-full shadow-xl transition-all duration-300 flex items-center"
                    disabled={isGenerating}
                  >
                    {isGenerating ? (
                      <>
                        <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                        生成中...
                      </>
                    ) : (
                      <>
                        最適化
                        <ArrowRight className="ml-2 h-5 w-5" />
                      </>
                    )}
                  </Button>
                </div>
              </form>
            </FloatingElement>

            <AnimatePresence>
              {isGenerating && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="w-full bg-gray-700 rounded-full h-2.5 mb-4 overflow-hidden">
                    <motion.div
                      className="h-2.5 rounded-full bg-gradient-to-r from-purple-400 to-pink-400"
                      initial={{ width: 0 }}
                      animate={{ width: `${progress}%` }}
                      transition={{ duration: 0.5 }}
                    />
                  </div>
                  <p className="text-gray-300 font-medium">生成中... {progress}%</p>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {generatedArticle && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="mt-8 p-6 bg-white/10 backdrop-blur-md rounded-xl shadow-lg border border-purple-500/30"
                >
                  <h2 className="text-2xl font-bold mb-4 text-white">生成された記事</h2>
                  <p className="text-left text-gray-300 leading-relaxed">{generatedArticle}</p>
                </motion.div>
              )}
            </AnimatePresence>

            <FloatingElement delay={1}>
              <p className="text-sm text-gray-400 font-medium">
                ※ 初回500ポイント無料。デポジット制で柔軟に利用可能。
              </p>
            </FloatingElement>

            <FloatingElement delay={1.2}>
              <motion.div
                className="flex justify-center"
                animate={{
                  y: [0, -10, 0],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <a href="#features" className="text-white hover:text-purple-400 transition-colors duration-300">
                  <ChevronDown className="w-8 h-8" />
                </a>
              </motion.div>
            </FloatingElement>
          </motion.div>
        </div>
      </main>

      <section id="features" className="py-20 bg-gray-900/50 backdrop-blur-sm">
        <div className="container mx-auto px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-4xl font-bold text-center mb-12 text-white tracking-tight"
          >
            <ShimmeringText>AIライティングの主な機能</ShimmeringText>
          </motion.h2>
          <div className="grid md:grid-cols-3 gap-8">
            <AnimatedFeature
              icon={Type}
              title="高度なSEO最適化"
              description="最新のアルゴリズムに基づき、検索エンジン上位表示を実現する構造化コンテンツを生成します。"
            />
            <AnimatedFeature
              icon={LayoutIcon}
              title="魅力的なレイアウト"
              description="ユーザーエンゲージメントを最大化する、視覚的に魅力的で読みやすいレイアウトを自動生成します。"
            />
            <AnimatedFeature
              icon={ImageIcon}
              title="最適化された画像"
              description="コンテンツに最適な画像を自動選択し、SEOとユーザー体験を向上させます。"
            />
          </div>
        </div>
      </section>

      <section id="pricing" className="py-20 bg-indigo-900/50 backdrop-blur-sm">
        <div className="container mx-auto px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-4xl font-bold text-center mb-12 text-white tracking-tight"
          >
            <ShimmeringText>選べる2つのプラン＆都度課金</ShimmeringText>
          </motion.h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <PricingCard
              title="プロフェッショナルプラン"
              price="月額 ○○,○○○円"
              features={[
                "記事作成し放題",
                "毎月○○ポイント付与",
                "画像最適化無制限"
              ]}
              isPopular={true}
            />
            <PricingCard
              title="スタンダードプラン"
              price="月額 ○,○○○円"
              features={[
                "月○○記事まで作成可能",
                "毎月○○ポイント付与",
                "画像最適化○回まで"
              ]}
              isPopular={false}
            />
          </div>
          <p className="text-center mt-8 text-gray-300 font-medium">＊ポイントを使って都度利用もOK</p>
        </div>
      </section>

      <section className="py-20 bg-gray-900/50 backdrop-blur-sm">
        <div className="container mx-auto px-4 text-center">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-4xl font-bold mb-6 text-white tracking-tight"
          >
            <ShimmeringText>コンテンツ戦略の未来、始めませんか？</ShimmeringText>
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl md:text-2xl mb-8 text-gray-300 font-medium leading-relaxed"
          >
            AIライティングで、あなたのコンテンツを次のレベルへ。
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <Button 
              size="lg" 
              className="bg-gradient-to-r from-purple-400 to-pink-400 hover:from-purple-500 hover:to-pink-500 text-white text-xl font-semibold py-4 px-10 rounded-lg transition-colors duration-300 transform hover:scale-105"
            >
              いますぐ無料で試してみる
            </Button>
          </motion.div>
          <p className="mt-4 text-gray-400 font-medium">＊初回500ポイントプレゼント中</p>
        </div>
      </section>

      <footer className="bg-gray-900/80 backdrop-blur-sm py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <p className="text-sm text-gray-400">&copy; 2024 AIライティング. All rights reserved.</p>
            </div>
            <div className="flex flex-wrap justify-center space-x-4">
              <button className="text-sm text-gray-400 hover:text-purple-400 transition-colors">
                プライバシーポリシー
              </button>
              <button className="text-sm text-gray-400 hover:text-purple-400 transition-colors">
                利用規約
              </button>
              <button className="text-sm text-gray-400 hover:text-purple-400 transition-colors">
                お問い合わせ
              </button>
            </div>
          </div>
        </div>
      </footer>

      <style jsx="true">{`
        @keyframes float {
          0%, 100% { transform: translate(0, 0) rotate(0deg); }
          25% { transform: translate(10px, 10px) rotate(5deg); }
          50% { transform: translate(-5px, 15px) rotate(-5deg); }
          75% { transform: translate(-15px, -10px) rotate(3deg); }
        }
      `}</style>
    </div>
  );
}

export default LandingPage;