import React from 'react';
import { SignIn, SignUp } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

const AuthWrapper = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingTop: '10vh',
      paddingX: 2,
    }}
  >
    <Box
      sx={{
        width: '100%',
        maxWidth: '400px',
      }}
    >
      {children}
    </Box>
  </Box>
);

export const SignInPage = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  return (
    <AuthWrapper>
      <SignIn 
        routing="path"
        path="/sign-in"
        signUpUrl="/sign-up"
        fallbackRedirectUrl={from}
        appearance={{
          layout: {
            socialButtonsVariant: 'iconButton',
            socialButtonsPlacement: 'bottom'
          }
        }}
      />
    </AuthWrapper>
  );
};

export const SignUpPage = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  return (
    <AuthWrapper>
      <SignUp 
        routing="path"
        path="/sign-up"
        signInUrl="/sign-in"
        fallbackRedirectUrl={from}
        appearance={{
          layout: {
            socialButtonsVariant: 'iconButton',
            socialButtonsPlacement: 'bottom'
          }
        }}
      />
    </AuthWrapper>
  );
};