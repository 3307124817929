import React from 'react';
import { ClerkProvider } from '@clerk/clerk-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme, CssBaseline } from '@mui/material';
import Header from './components/Header';
import Menu from './components/Menu';
import ArticleList from './components/ArticleList';
import ArticleView from './components/ArticleView';
import CreateOutline from './components/CreateOutline';
import EditArticle from './components/EditArticle';
import TargetAudiencePage from './components/TargetAudiencePage';
import CreateTargetAudience from './components/CreateTargetAudience';
import GeneratedArticlePage from './components/GeneratedArticlePage';
import GenerateArticlePage from './components/GenerateArticlePage';
import HomePage from './components/HomePage';
import { SignInPage, SignUpPage } from './components/AuthComponents';
import UserProfile from './components/UserProfile';
import ProtectedRoute from './components/ProtectedRoute';
import LandingPage from './components/LandingPage';
import PricingPage from './components/PricingPage';
import Guide from './components/Guide';
import './index.css';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error("Missing Publishable Key")
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
      letterSpacing: '-0.037em',
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 400,
      letterSpacing: '-0.037em',
    },
    button: {
      textTransform: 'none',
      fontFamily: '"Inter", "Roboto", Arial, sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#000000',
        },
      },
    },
  },
});

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div className="min-h-screen font-inter antialiased">
            <Header />
            <main className="relative bg-gray-50">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/menu" element={<ProtectedRoute><Menu /></ProtectedRoute>} />
                <Route path="/articles" element={<ProtectedRoute><ArticleList /></ProtectedRoute>} />
                <Route path="/articles/:id" element={<ProtectedRoute><ArticleView /></ProtectedRoute>} />
                <Route path="/create" element={<ProtectedRoute><CreateOutline /></ProtectedRoute>} />
                <Route path="/edit/:id" element={<ProtectedRoute><EditArticle /></ProtectedRoute>} />
                <Route path="/target-audience/:id" element={<ProtectedRoute><TargetAudiencePage /></ProtectedRoute>} />
                <Route path="/create-target-audience/:id" element={<ProtectedRoute><CreateTargetAudience /></ProtectedRoute>} />
                <Route path="/generated-article/:id" element={<ProtectedRoute><GeneratedArticlePage /></ProtectedRoute>} />
                <Route path="/generate-article/:id" element={<ProtectedRoute><GenerateArticlePage /></ProtectedRoute>} />
                <Route path="/quick-generate" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
                <Route path="/sign-in/*" element={<SignInPage />} />
                <Route path="/sign-up/*" element={<SignUpPage />} />
                <Route path="/landing" element={<LandingPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/guide" element={<Guide />} />
              </Routes>
            </main>
          </div>
        </Router>
      </MuiThemeProvider>
    </ClerkProvider>
  );
}

export default App;