import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import PageIllustration from './PageIllustration';
import { Trash2, Send, Users, X } from 'lucide-react';

function GeneratedArticlePage() {
  const [generatedArticle, setGeneratedArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [sending, setSending] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [errorDetails, setErrorDetails] = useState('');
  const [notification, setNotification] = useState({ show: false, message: '', type: 'success' });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGeneratedArticle = async () => {
      try {
        const response = await axios.get(`/api/generated-article/${id}`);
        setGeneratedArticle(response.data);
      } catch (error) {
        console.error('Error fetching generated article:', error);
        showNotification('記事の取得に失敗しました', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchGeneratedArticle();
  }, [id]);

  const showNotification = (message, type = 'success') => {
    setNotification({ show: true, message, type });
    setTimeout(() => {
      setNotification({ show: false, message: '', type: 'success' });
    }, 5000);
  };

  const handleDelete = async () => {
    if (window.confirm('この記事を削除してもよろしいですか？')) {
      setDeleting(true);
      try {
        await axios.delete(`/api/generated-article/${id}`);
        setGeneratedArticle(null);
        showNotification('記事が削除されました');
      } catch (error) {
        console.error('Error deleting generated article:', error);
        showNotification('記事の削除に失敗しました', 'error');
      } finally {
        setDeleting(false);
      }
    }
  };

  const handleSendToWordPress = async () => {
    if (!selectedPlatform) {
      showNotification('プラットフォームを選択してください', 'warning');
      return;
    }

    setSending(true);
    try {
      const response = await axios.post(`/api/send-to-wordpress/${id}`, {
        platform: selectedPlatform
      });
      showNotification('WordPressに記事が送信されました');
      setGeneratedArticle(prevState => ({
        ...prevState,
        wordpressLink: response.data.link
      }));
      setOpenSendDialog(false);
    } catch (error) {
      console.error('Error sending to WordPress:', error);
      let errorMessage = 'WordPressへの送信に失敗しました';
      if (error.response?.data) {
        setErrorDetails(JSON.stringify(error.response.data, null, 2));
        errorMessage += ' - 詳細を確認してください';
      }
      showNotification(errorMessage, 'error');
    } finally {
      setSending(false);
    }
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-4xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="relative overflow-hidden rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm md:p-8">
            {/* Top bar with actions */}
            <div className="mb-6 flex items-center justify-between">
              <h1 className="text-3xl font-bold tracking-[-0.037em] text-gray-800">
                生成された記事
              </h1>
              {generatedArticle && (
                <div className="flex gap-2">
                  <button
                    onClick={() => setOpenSendDialog(true)}
                    disabled={sending || deleting}
                    className="inline-flex items-center rounded-xl bg-white p-2 text-gray-600 shadow-sm hover:bg-gray-50 disabled:opacity-50"
                    title="WordPressに送信"
                  >
                    <Send size={20} />
                  </button>
                  <button
                    onClick={handleDelete}
                    disabled={sending || deleting}
                    className="inline-flex items-center rounded-xl bg-white p-2 text-red-500 shadow-sm hover:bg-red-50 disabled:opacity-50"
                    title="削除"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              )}
            </div>

            {/* Content */}
            {generatedArticle ? (
              <>
                <div className="prose max-w-none">
                  <div dangerouslySetInnerHTML={{ __html: generatedArticle.content }} />
                </div>

                {generatedArticle.wordpressLink && (
                  <div className="mt-6 rounded-xl bg-gray-50 p-4">
                    <p className="text-sm text-gray-600">
                      WordPress URL:{' '}
                      <a
                        href={generatedArticle.wordpressLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {generatedArticle.wordpressLink}
                      </a>
                    </p>
                  </div>
                )}

                <div className="mt-8">
                  <Link
                    to={`/target-audience/${id}`}
                    className="inline-flex items-center rounded-xl bg-white px-6 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50"
                  >
                    <Users className="mr-2 h-4 w-4" />
                    ターゲット設定を確認
                  </Link>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center py-12">
                <p className="mb-6 text-gray-600">記事はまだ生成されていません</p>
                <button
                  onClick={() => navigate(`/generate-article/${id}`)}
                  className="inline-flex items-center rounded-xl bg-gradient-to-t from-gray-800 to-gray-700 px-6 py-3 text-sm font-medium text-white shadow-sm hover:to-gray-600"
                >
                  記事を生成
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Send to WordPress Dialog */}
      {openSendDialog && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="w-full max-w-md overflow-hidden rounded-2xl bg-white p-6 shadow-xl">
            <div className="mb-6 flex items-center justify-between">
              <h2 className="text-xl font-bold text-gray-800">プラットフォームを選択</h2>
              <button
                onClick={() => setOpenSendDialog(false)}
                className="rounded-lg p-2 text-gray-400 hover:bg-gray-100"
              >
                <X size={20} />
              </button>
            </div>

            <select
              value={selectedPlatform}
              onChange={(e) => setSelectedPlatform(e.target.value)}
              className="mb-6 w-full rounded-xl border border-gray-200 p-3 outline-none focus:border-gray-400"
            >
              <option value="">プラットフォームを選択...</option>
              <option value="oncasi">Oncasi Stars</option>
              <option value="xp">XP</option>
              <option value="test">TEST</option>
            </select>

            <div className="flex justify-end gap-3">
              <button
                onClick={() => setOpenSendDialog(false)}
                className="rounded-xl px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100"
              >
                キャンセル
              </button>
              <button
                onClick={handleSendToWordPress}
                disabled={!selectedPlatform || sending}
                className="inline-flex items-center rounded-xl bg-gradient-to-t from-gray-800 to-gray-700 px-4 py-2 text-sm font-medium text-white hover:to-gray-600 disabled:opacity-50"
              >
                {sending ? (
                  <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                ) : (
                  '送信'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Error Details Dialog */}
      {errorDetails && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="w-full max-w-md overflow-hidden rounded-2xl bg-white p-6 shadow-xl">
            <div className="mb-6 flex items-center justify-between">
              <h2 className="text-xl font-bold text-gray-800">エラーの詳細</h2>
              <button
                onClick={() => setErrorDetails('')}
                className="rounded-lg p-2 text-gray-400 hover:bg-gray-100"
              >
                <X size={20} />
              </button>
            </div>
            <div className="mb-6 rounded-xl bg-gray-50 p-4">
              <pre className="whitespace-pre-wrap break-words text-sm text-gray-600">
                {errorDetails}
              </pre>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setErrorDetails('')}
                className="rounded-xl px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100"
              >
                閉じる
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Notification */}
      {notification.show && (
        <div className="fixed bottom-4 right-4 z-50 animate-fade-in">
          <div 
            className={`rounded-lg p-4 shadow-lg ${
              notification.type === 'error' 
                ? 'bg-red-500 text-white' 
                : notification.type === 'warning'
                ? 'bg-yellow-500 text-white'
                : 'bg-green-500 text-white'
            }`}
          >
            {notification.message}
          </div>
        </div>
      )}
    </section>
  );
}

export default GeneratedArticlePage;